var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-wrap departure-situation" },
    [
      _c("div", { staticClass: "form-wrap" }, [
        _c("div", { staticClass: "seach" }, [
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-button", {
                staticClass: "refresh",
                attrs: { type: "primary", icon: "el-icon-refresh" },
                on: { click: _vm.onHandleRefresh },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  clearable: "",
                  "start-placeholder": "反馈时间起",
                  "end-placeholder": "反馈时间止",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                },
                on: {
                  change: (val) => _vm.addTimeChange(val, "feedback_time"),
                },
                model: {
                  value: _vm.feedback_time,
                  callback: function ($$v) {
                    _vm.feedback_time = $$v
                  },
                  expression: "feedback_time",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  clearable: "",
                  "start-placeholder": "提交时间起",
                  "end-placeholder": "提交时间止",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                },
                on: { change: (val) => _vm.addTimeChange(val, "create_time") },
                model: {
                  value: _vm.create_time,
                  callback: function ($$v) {
                    _vm.create_time = $$v
                  },
                  expression: "create_time",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入提交人电话", clearable: "" },
                model: {
                  value: _vm.formData.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "mobile", $$v)
                  },
                  expression: "formData.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择提交人角色" },
                  model: {
                    value: _vm.formData.customer_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "customer_type", $$v)
                    },
                    expression: "formData.customer_type",
                  },
                },
                _vm._l(_vm.roleList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入提交人姓名", clearable: "" },
                model: {
                  value: _vm.formData.member_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "member_name", $$v)
                  },
                  expression: "formData.member_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择提交人所在仓",
                    loading: _vm.loadingCityStore,
                  },
                  model: {
                    value: _vm.formData.logistics_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "logistics_id", $$v)
                    },
                    expression: "formData.logistics_id",
                  },
                },
                _vm._l(_vm.cityStoreList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id.toString() },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择集配中心" },
                  model: {
                    value: _vm.formData.logistic_business_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "logistic_business_id", $$v)
                    },
                    expression: "formData.logistic_business_id",
                  },
                },
                _vm._l(_vm.logisticsList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择类型" },
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                _vm._l(_vm.typeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择回复状态" },
                  model: {
                    value: _vm.formData.feedback_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "feedback_status", $$v)
                    },
                    expression: "formData.feedback_status",
                  },
                },
                _vm._l(_vm.statusList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.onSearch },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    disabled: !_vm.tableData.length,
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.exportHandle },
                },
                [_vm._v("导 出")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "table-wrap" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "100%",
                border: true,
                "header-cell-style": {
                  color: "#333333",
                  background: "#EFF6FF",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "create_time",
                  align: "center",
                  label: "提交日期",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", align: "center", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.typeList.find(
                                (fItem) => fItem.id == scope.row.type
                              )?.name || "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "member.fullname",
                  align: "center",
                  label: "提交人",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "mobile",
                  align: "center",
                  label: "提交人手机号",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_name",
                  align: "center",
                  label: "提交所在城市仓",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.member
                                ? scope.row.member.logistics.name
                                : "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_name",
                  align: "center",
                  label: "集配中心",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.member &&
                                scope.row.member.logistics &&
                                scope.row.member.logistics.business
                                ? scope.row.member.logistics.business.name
                                : "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "customer_type",
                  align: "center",
                  label: "提交人角色",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.roleList.find(
                                (item) => item.id == scope.row.customer_type
                              ).name
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "car_list",
                  align: "center",
                  label: "提交内容",
                  width: "300",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: scope.row.content,
                              placement: "top",
                              disabled:
                                scope.row.content.length < 60 ? true : false,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tooltipcontent",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [_vm._v(" " + _vm._s(scope.row.content) + " ")]
                            ),
                            _c("div", { staticClass: "content-cell" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.truncateText(scope.row.content)) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "pic", align: "center", label: "提交图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pic
                          ? _c(
                              "div",
                              _vm._l(
                                scope.row.pic.split(","),
                                function (item, index) {
                                  return _c("el-image", {
                                    key: index,
                                    staticStyle: {
                                      width: "30px",
                                      height: "30px",
                                      "margin-right": "5px",
                                    },
                                    attrs: {
                                      src: item,
                                      "preview-src-list":
                                        scope.row.pic.split(","),
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "feedback",
                  align: "center",
                  label: "回复内容",
                  width: "300",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: scope.row.feedback,
                              placement: "top",
                              disabled:
                                scope.row.feedback.length < 20 ? true : false,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tooltipcontent",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [_vm._v(" " + _vm._s(scope.row.feedback) + " ")]
                            ),
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.feedback
                                      ? scope.row.feedback.slice(0, 20)
                                      : "-"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "feedback_status",
                  align: "center",
                  label: "回复状态",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.feedback_status == _vm.statusList[0].id
                                ? "已回复"
                                : "未回复"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "nickname", align: "center", label: "回复人" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.business_user
                                ? scope.row.business_user.nickname
                                : ""
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "report_at", align: "center", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.feedback
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(1, scope.row)
                                  },
                                },
                              },
                              [_vm._v("回复 ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(2, scope.row)
                              },
                            },
                          },
                          [_vm._v(" 详情 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("editDetail", {
        ref: "editDetailRef",
        attrs: { roleList: _vm.roleList, typeList: _vm.typeList },
        on: { getList: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }