<template>
  <div>
    <!-- 回复 || 查看详情 弹窗-->
    <el-dialog
      :center="true"
      @close="tableVisible = false"
      :visible="tableVisible"
      v-if="tableVisible"
      :title="title"
      width="50%"
    >
      <el-descriptions
        title=""
        :column="1"
        :labelStyle="{
          width: '70px',
        }"
      >
        <el-descriptions-item label="问题类型">
          {{ typeList.find((item) => item.id == detailInfo.type).name || "-" }}
        </el-descriptions-item>
        <el-descriptions-item label="提交人">
          {{ detailInfo.member.fullname }}({{ detailInfo.member.mobile }})--{{
            detailInfo.member.logistics.name
          }}--{{
            roleList.find((item) => item.id == detailInfo.customer_type).name
          }}
        </el-descriptions-item>
        <el-descriptions-item label="问题描述">
          {{ detailInfo.content }}
        </el-descriptions-item>
        <el-descriptions-item label="图片">
          <div v-if="detailInfo.pic">
            <el-image
              v-for="(item, index) in detailInfo.pic.split(',')"
              :key="index"
              style="width: 100px; height: 100px; margin-right: 10px"
              :src="item"
              :preview-src-list="detailInfo.pic.split(',')"
            ></el-image>
          </div>
          <div v-else>-</div>
        </el-descriptions-item>
        <el-descriptions-item label="回复内容">
          <el-input
            style="width: 300px"
            type="textarea"
            :rows="5"
            :disabled="disabledData"
            placeholder="请输入回复内容"
            v-model="feedback"
            maxlength="500"
            show-word-limit
          >
          </el-input>
        </el-descriptions-item>
        <el-descriptions-item label="回复图片">
          <b-file-upload
            :accepts="['jpg', 'jpeg', 'png']"
            :limit="5"
            :disabled="disabledData"
            v-model="detailPicList"
          ></b-file-upload>
        </el-descriptions-item>
      </el-descriptions>
      <template #footer v-if="!disabledData">
        <el-button @click="tableVisible = false">返回</el-button>
        <el-button @click="sureConfirm" type="primary" :loading="bntloading"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
import BFileUpload from "@/components/business/BFileUpload/index.vue";
import { postComplaintFeedbackFeedback } from "@/api/general/complaint-suggestion.js";
export default {
  name: "editDetail",
  props: ["typeList", "roleList"],
  components: { BFileUpload },
  data() {
    return {
      detailPicList: [],
      detailInfo: {},
      feedback_pic: "", // 回复图片
      disabledData: false,
      feedback: "", // 回复内容
      tableVisible: false,
      bntloading: false,
      title: "",
    };
  },
  computed: {},
  created() {},
  watch: {
    /**
     * 图片发生改变
     */
    detailPicList() {
      const res = this.detailPicList?.map((item) => item.url).join(",");
      this.feedback_pic = res;
    },
  },
  methods: {
    // 初始化
    onInitData(type, row) {
      console.log(type, row, "www");
      this.feedback = "";
      this.detailPicList = [];
      this.feedback_pic = "";
      this.detailInfo = row;
      if (type == 1) {
        this.title = "添加回复";
        this.disabledData = false;
      } else {
        this.disabledData = true;
        this.title = "回复详情";
        this.feedback = this.detailInfo.feedback;
        let tmpPic = [];
        if (this.detailInfo.feedback_pic) {
          this.detailInfo.feedback_pic.split(",").map((item) => {
            tmpPic.push({
              name: item,
              url: item,
            });
          });
        }
        this.detailPicList = tmpPic.length > 0 ? tmpPic : [];
        this.feedback_pic = this.detailInfo.feedback_pic
          ? this.detailInfo.feedback_pic
          : "";
      }
      this.tableVisible = true;
    },
    async sureConfirm() {
      console.log(this.detailPicList, "detailPicList===");
      // 回复用户

      if (!this.feedback) {
        this.tool.message("请输入回复内容！", "error");
        return;
      }
      //   if (this.detailPicList.length == 0) {
      //   this.tool.message("请上传回复图片！", "error");
      //   return;
      // }
      this.bntloading = true;
      try {
        const res = await postComplaintFeedbackFeedback({
          id: this.detailInfo.id,
          feedback: this.feedback,
          feedback_pic: this.feedback_pic,
        });
        this.$message({
          type: "success",
          message: "回复成功",
        });
        this.tableVisible = false;
        this.$emit("getList");
      } catch (error) {
        console.log(error, "postComplaintFeedbackFeedback");
      } finally {
        this.bntloading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
