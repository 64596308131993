import axios from "../http";
import { BASE } from "../index";
export const URL = BASE.PRO1;

/**
 * 投诉列表
 * @param {*} params
 * @returns
 */
export function postComplaintFeedbackList(params = {}) {
  return axios.post(`${URL}/complaint/feedback/list`, params);
}

/**
 * 反馈
 * @param {*} params
 * @returns
 */
export function postComplaintFeedbackFeedback(params = {}) {
  return axios.post(`${URL}/complaint/feedback/feedback`, params);
}
