<template>
  <div class="page-wrap departure-situation">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="feedback_time"
            start-placeholder="反馈时间起"
            end-placeholder="反馈时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'feedback_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-date-picker
            type="daterange"
            clearable
            v-model="create_time"
            start-placeholder="提交时间起"
            end-placeholder="提交时间止"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="(val) => addTimeChange(val, 'create_time')"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.mobile"
            placeholder="请输入提交人电话"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.customer_type"
            clearable
            placeholder="请选择提交人角色"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in roleList"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="formData.member_name"
            placeholder="请输入提交人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            filterable
            v-model="formData.logistics_id"
            clearable
            placeholder="请选择提交人所在仓"
            :loading="loadingCityStore"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id.toString()"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select v-model="formData.type" clearable placeholder="请选择类型">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in typeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.feedback_status"
            clearable
            placeholder="请选择回复状态"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in statusList"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            :disabled="!tableData.length"
            @click="exportHandle"
            icon="el-icon-download"
            >导 出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="create_time" align="center" label="提交日期">
        </el-table-column>
        <el-table-column prop="type" align="center" label="类型">
          <template slot-scope="scope">
            {{
              typeList.find((fItem) => fItem.id == scope.row.type)?.name || "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="member.fullname" align="center" label="提交人">
        </el-table-column>
        <el-table-column prop="mobile" align="center" label="提交人手机号">
        </el-table-column>
        <el-table-column
          prop="delivery_name"
          align="center"
          label="提交所在城市仓"
        >
          <template slot-scope="scope">
            {{ scope.row.member ? scope.row.member.logistics.name : "-" }}
          </template>
        </el-table-column>
        <el-table-column prop="delivery_name" align="center" label="集配中心">
          <template slot-scope="scope">
            {{
              scope.row.member &&
              scope.row.member.logistics &&
              scope.row.member.logistics.business
                ? scope.row.member.logistics.business.name
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="customer_type" align="center" label="提交人角色">
          <template slot-scope="scope">
            {{
              roleList.find((item) => item.id == scope.row.customer_type).name
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="car_list"
          align="center"
          label="提交内容"
          width="300"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.content"
              placement="top"
              :disabled="scope.row.content.length < 60 ? true : false"
            >
              <div slot="content" class="tooltipcontent">
                {{ scope.row.content }}
              </div>
              <div class="content-cell">
                {{ truncateText(scope.row.content) }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="pic" align="center" label="提交图片">
          <template slot-scope="scope">
            <div v-if="scope.row.pic">
              <el-image
                v-for="(item, index) in scope.row.pic.split(',')"
                :key="index"
                style="width: 30px; height: 30px; margin-right: 5px"
                :src="item"
                :preview-src-list="scope.row.pic.split(',')"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="feedback"
          align="center"
          label="回复内容"
          width="300"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.feedback"
              placement="top"
              :disabled="scope.row.feedback.length < 20 ? true : false"
            >
              <div slot="content" class="tooltipcontent">
                {{ scope.row.feedback }}
              </div>
              <div>
                {{ scope.row.feedback ? scope.row.feedback.slice(0, 20) : "-" }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="feedback_status" align="center" label="回复状态">
          <template slot-scope="scope">
            {{
              scope.row.feedback_status == statusList[0].id
                ? "已回复"
                : "未回复"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="nickname" align="center" label="回复人">
          <template slot-scope="scope">
            {{
              scope.row.business_user ? scope.row.business_user.nickname : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="report_at" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleEdit(1, scope.row)"
              v-if="!scope.row.feedback"
              >回复
            </el-button>
            <el-button type="text" @click="handleEdit(2, scope.row)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <editDetail
      ref="editDetailRef"
      :roleList="roleList"
      :typeList="typeList"
      @getList="getList"
    />
  </div>
</template>
<script>
import { BASE } from "@/api";
import { postComplaintFeedbackList } from "@/api/general/complaint-suggestion.js";
import editDetail from "./modules/editDetail.vue";

export default {
  name: "complaint-suggestion",
  components: {
    editDetail,
  },
  data() {
    return {
      logisticsList: [],
      video_time: [],
      loading: false,
      loadingCityStore: false, //城市仓加载loading
      disabledExport: false, // 导出禁用
      cityStoreList: [], // 城市仓列表
      feedback_time: [],
      create_time: [],
      formData: {
        logistic_business_id: "", // 集配中心id
        page: 1, //	number	页码
        pageSize: 10, //	number	页大小
        create_time: "", //提交时间
        feedback_time: "", //反馈时间
        customer_type: "", //提交人角色，1-用户，2-仓主，3-供应商
        type: "", //类型，1-投诉，2-建议
        member_name: "", //提交人名称
        mobile: "", //提交人电话
        logistics_id: "", //	number	筛选城市仓id
        feedback_status: "", //回复状态1-已回复，0-未回复
      }, // 表单参数
      roleList: [
        { name: "用户", id: 1 },
        { name: "仓主", id: 2 },
        { name: "供应商", id: 3 },
      ], //角色列表
      typeList: [
        { name: "投诉", id: 1 },
        { name: "建议", id: 2 },
        { name: "举报", id: 3 },
      ], //类型列表
      statusList: [
        { name: "已回复", id: 1 },
        { name: "未回复", id: 0 },
      ], // 回复状态
      tableData: [], // 城市仓列表
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    // 获取城市仓列表
    async citylist() {
      this.loadingCityStore = true;
      try {
        const res = await this.$api.general.logisticsList({
          name: "",
          page: 1,
          pageSize: 1000,
        });
        console.log(res, "获取数据");
        this.cityStoreList = res.data.data;
        console.log(this.cityStoreList, "this.cityStoreList==");
      } catch (error) {
        console.log(error, "logisticsList");
      } finally {
        this.loadingCityStore = false;
      }
    },
    // 保存更新列表
    getList() {
      this.formData.page = 1;
      this.hqlist();
    },
    truncateText(text) {
      const maxLines = 2;
      const maxCharsPerLine = 30;
      let truncated = "";
      let lineCount = 0;
      let lineLength = 0;
      for (let i = 0; i < text.length; i++) {
        if (lineCount >= maxLines) {
          break;
        }
        // 假设一个汉字占两个字符宽度，这里简单处理为加2
        // 实际上，你可能需要根据实际字体和样式来调整
        lineLength += 1;
        if (lineLength >= maxCharsPerLine) {
          lineCount++;
          lineLength = 0;
        }

        truncated += text[i];
      }
      console.log(truncated.length, "ee===");
      return truncated;
    },
    // 添加回复 || 查看详情
    handleEdit(type, row) {
      this.$refs.editDetailRef.onInitData(type, row);
    },
    // 提交时间 || 反馈时间
    addTimeChange(val, name) {
      if (name == "create_time") {
        if (val) {
          this.formData.create_time = val.join(",");
        } else {
          this.formData.create_time = "";
        }
      } else {
        if (val) {
          this.formData.feedback_time = val.join(",");
        } else {
          this.formData.feedback_time = "";
        }
      }
    },
    // 初始化
    onInitData() {
      this.getAjaxLogisticsList();
      this.citylist();
      this.hqlist();
    },
    // 获取列表
    async hqlist() {
      this.loading = true;
      try {
        const res = await postComplaintFeedbackList(this.formData);
        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postDepartReportList");
      }
    },
    /**
     * 导出
     */
    exportHandle() {
      let url = `${
        BASE.PRO1
      }/complaint/feedback/export?token=${sessionStorage.getItem("token")}`;
      for (let key in this.formData) {
        if (key != "page" && key != "pageSize") {
          url = url + `&${key}=${this.formData[key]}`;
        }
      }
      window.open(url, "_blank");
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.create_time = [];
      this.feedback_time = [];
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="scss" scoped>
.content-cell {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.position {
  position: relative;
}
.from-wraps {
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
}

.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .showImg {
    text-align: center;
    height: 76vh;
  }
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        display: flex;
        align-items: center;
        .divname {
          width: 100px;
        }
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
.tooltipcontent {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
}
</style>
