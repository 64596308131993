var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.tableVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.tableVisible,
                title: _vm.title,
                width: "50%",
              },
              on: {
                close: function ($event) {
                  _vm.tableVisible = false
                },
              },
              scopedSlots: _vm._u(
                [
                  !_vm.disabledData
                    ? {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.tableVisible = false
                                  },
                                },
                              },
                              [_vm._v("返回")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.bntloading,
                                },
                                on: { click: _vm.sureConfirm },
                              },
                              [_vm._v("确定")]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _c(
                "el-descriptions",
                {
                  attrs: {
                    title: "",
                    column: 1,
                    labelStyle: {
                      width: "70px",
                    },
                  },
                },
                [
                  _c("el-descriptions-item", { attrs: { label: "问题类型" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.typeList.find(
                            (item) => item.id == _vm.detailInfo.type
                          ).name || "-"
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "提交人" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.detailInfo.member.fullname) +
                        "(" +
                        _vm._s(_vm.detailInfo.member.mobile) +
                        ")--" +
                        _vm._s(_vm.detailInfo.member.logistics.name) +
                        "--" +
                        _vm._s(
                          _vm.roleList.find(
                            (item) => item.id == _vm.detailInfo.customer_type
                          ).name
                        ) +
                        " "
                    ),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "问题描述" } }, [
                    _vm._v(" " + _vm._s(_vm.detailInfo.content) + " "),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "图片" } }, [
                    _vm.detailInfo.pic
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.detailInfo.pic.split(","),
                            function (item, index) {
                              return _c("el-image", {
                                key: index,
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  src: item,
                                  "preview-src-list":
                                    _vm.detailInfo.pic.split(","),
                                },
                              })
                            }
                          ),
                          1
                        )
                      : _c("div", [_vm._v("-")]),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "回复内容" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          type: "textarea",
                          rows: 5,
                          disabled: _vm.disabledData,
                          placeholder: "请输入回复内容",
                          maxlength: "500",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.feedback,
                          callback: function ($$v) {
                            _vm.feedback = $$v
                          },
                          expression: "feedback",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "回复图片" } },
                    [
                      _c("b-file-upload", {
                        attrs: {
                          accepts: ["jpg", "jpeg", "png"],
                          limit: 5,
                          disabled: _vm.disabledData,
                        },
                        model: {
                          value: _vm.detailPicList,
                          callback: function ($$v) {
                            _vm.detailPicList = $$v
                          },
                          expression: "detailPicList",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }